import React from 'react'
import { Row, Col } from 'reactstrap'

export default function FeatureSection(props) {

    let icon_section = <img src={props.img}></img>;
    let data_section = <div className="info-div"><h2>{props.title}</h2><p>{props.description}</p></div>

    return (
        <Row>
            <Col sm={6} xs={12}>
                { props.even ? icon_section : data_section }
            </Col>
            <Col sm={6} xs={12}>
                { !props.even ? icon_section : data_section }
            </Col>
        </Row>
    );
}