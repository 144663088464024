import React from 'react';
import Header from './Header'
import Main from './Main'
import { Container, Row, Col } from 'reactstrap'
import './App.css';
import FeatureSection from './FeatureSection';
import Contact from './Contact';
import Updates from './Updates';
import Footer from './Footer';

const info_points = [
  {
    title: 'Leverage Creativity',
    icon: '',
    desc: `Use image and video content showcasing your creativity and skills and get 
    paid based on your specified monthly subscription fee as well as tips. Payments are securely 
    handled through Stripe. Start making money today!`
  },
  {
    title: 'Security',
    icon: '',
    desc: `By using the highest level of TLS encryption and only trusted third-party services such as Stripe 
    to handle payments, we secure all of your information. We also watermark, digitally sign, and encrypt 
    your content to prevent it from being stolen and put online.`
  },
  {
    title: 'Get Paid',
    icon: '',
    desc: `Pay out every two weeks directly into your bank account. We take care of the taxes
    and everything else. Make 90% of your subscription income and 100% of all tips. `
  }
]

const progress_points = {
  planned: [
    { icon: 'p1.svg', name: 'Custom subscription model' },
    { icon: 'p2.svg', name: 'Unlimited tips' },
    { icon: 'p3.svg', name: 'Photo and video watermarking option' },
    { icon: 'p4.svg', name: 'Easy share links' }
  ],
  future: [
    { icon: 'f1.svg', name: 'Livestreaming' },
    { icon: 'f2.svg', name: 'Scheduled posts' },
    { icon: 'f3.svg', name: 'Social media integration' },
    { icon: 'f4.svg', name: 'Much more!' }
  ]
}

function App() {
  
  return (
    <div className="App">
      
      <Header></Header>

      <Main></Main>

      <Container>
        { info_points.map((e, i) => { return <FeatureSection title={e.title} description={e.desc} img={e.icon} even={ i % 2 === 0 } /> }) }
      </Container>

      <Contact></Contact>

      <Updates planned={ progress_points.planned } future={ progress_points.future }></Updates>

      <Footer></Footer>

    </div>
  );
}

export default App;
