import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import './Updates.css'

export default function Updates(props) {

    return(
        <Container className="updates__container">
            <h2>Features and Updates</h2>
            <p>As we grow, new features will be added on a regular basis to help you grow your content</p>
            <Row>
                <Col xs={12} sm={6}>
                    <h3>In Progress</h3>
                    <ul>
                        { props.planned.map(e => { return <li><img src={e.icon}></img>{e.name}</li> }) }
                    </ul>
                </Col>
                <Col xs={12} sm={6}>
                    <h3>Future</h3>
                    <ul>
                        { props.future.map(e => { return <li><img src={e.icon}></img>{e.name}</li> }) }
                    </ul>
                </Col>
            </Row>
        </Container>
    );

}