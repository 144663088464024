import React from 'react';


export default function Header(props) {



    return (
    <nav>
        <img className="logo" src="/logo.png"></img>
        <h3 className="coming-soon-title">Coming Soon</h3>
        
    </nav>);
}