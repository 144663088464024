import React from 'react'
import './Footer.css'
import AppData from './AppData.json'

export default function Footer(props) {




    return (
    <footer>
        <p class="footer__disclaimer">© { (new Date()).getFullYear().toString() } { AppData.name }. All Rights Reserved</p>
    </footer>);
}