import React, { useState } from 'react';
import { Container } from 'reactstrap'
import firebase from './Firebase'
import './Contact.css'

export default function Contact(props) {

    const [email, setEmail] = useState('');

    const [show, shouldShow] = useState(false);

    function isValidEmail(str = '') {
        return str.includes('.') && str.includes('@');
    }

    async function sendEmail(email = '') {

        let dbres1 = await firebase.firestore().collection('Prospects').where('Email', '==', email).get();
        if (dbres1.size === 0) {
            let dbres = await firebase.firestore().collection('Prospects').add({
                'Email': email,
                'Created': new Date()
            });
        }
        shouldShow(true)
    }

    function showMessageBox(message = '') {

    }

    function hideMessageBox() {

    }

    return (
        <Container className="contact__container">
            <h3>Stay Updated</h3>
            <p>Keep up to date with our initial release date and features</p>

            <form onSubmit={e => e.preventDefault() } style={{display: !show ? 'block' : 'none'}}>
                <div className="contact__inputcontainer">
                    <label>Email</label>
                    <input onChange={e => setEmail(e.target.value)} value={email} type='email'></input>
                    <button onClick={_ => sendEmail(email)} style={{opacity: isValidEmail(email) ? 1 : 0}} >Add Me</button>
                </div>
            </form>

            <div className='contact__messagebox' style={{display: show ? 'block' : 'none'}}>
                <h4>Thank You</h4>
                <p>Thank you for your interest, we'll send you important information regarding our product launches as well
                    as any other updates
                </p>
            </div>
        </Container>
    );
}