import React from 'react'
import './profile.css'

export default function() {

    return (
    <div className="profile__container center">
        <div className="profile__back"></div>
        <div className="profile__subscribe"></div>
        <img draggable={false} className="profile__img" src="./beautiful-1274056_640.jpg"></img>
        <img className="profile_usericon" src="./user.png"></img>
        <p className="profile__username">@theireyesonly</p>

        <div className="profile__data">
            <div className="profile_stat profile_d1"> 
                <h3>40</h3>
                <p>Images</p>
            </div>
            <div className="profile_stat profile_d2"> 
                <h3>13</h3>
                <p>Videos</p>
            </div>
            <div className="profile_stat profile_d3"> 
                <h3>53</h3>
                <p>Total</p>
            </div>
        </div>

        <div className="profile__subscribebutton">Subscribe</div>
    </div>);
}