import * as firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyBrTmqOm5h6Qq3n99g4Bc-tPc1lTTRmB4g",
    authDomain: "theireyesonly.firebaseapp.com",
    databaseURL: "https://theireyesonly.firebaseio.com",
    projectId: "theireyesonly",
    storageBucket: "theireyesonly.appspot.com",
    messagingSenderId: "312085298029",
    appId: "1:312085298029:web:18b73c317fc36ca7603864",
    measurementId: "G-1GGJ5GTW9N"
  };

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;