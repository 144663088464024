import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Profile from './Profile'

export default function Main(props) {

    return (
        <div className="main-container">
            <Container>
                <Row>
                    <Col xs={12} sm={6}>
                        <div class="title-container">
                            <h2>Their Eyes Only</h2>
                            <p>An intuitive, safe, and easy-to-use pay-per view application. 
                                Make money off your exclusive content in just a matter of minutes.
                                </p>
                            <ul>
                                <li>Photo or video pay-per-view content</li>
                                <li>Quick and easy payout system</li>
                                <li>Keep 90% of revenue plus all tips</li>
                            </ul>
                            <button className="center main__button">Coming Soon</button>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Profile></Profile>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}